export function scrollCatcher() {
    let className = "navbar-scrolled";
    let nav = $('#topNav');
    let light = $(".navbar-light-brand");
    let dark = $(".navbar-dark-brand");
    let scrollPrompter = $("#scroll-prompter");
    let offset = nav.offset().top;
    if (offset > 100) {
        nav.addClass(className);
        light.show();
        dark.hide();
        let newOpacity = 1 - ((offset - 150) / 100);
        newOpacity = (newOpacity > 0 ? newOpacity : 0);
        scrollPrompter.animate({
            opacity: newOpacity
        }, 0.1)
    } else {
        nav.removeClass(className);
        light.hide();
        dark.show();
        scrollPrompter.fadeIn();
    }
}