import Plyr from "plyr";
require("jquery-ui/ui/effect");
require("jquery-ui/ui/effects/effect-scale");
require("jquery-ui/ui/effects/effect-size");

function toggleMastheadScale(){
    $("#masthead-text").toggle({
        effect: "scale",
        direction: "horizontal",
        origin: ["middle", "left"]
    });
}

export function initPlyr() {
    let p = new Plyr('#player', {
        youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
    });
    p.on('playing', function() {
        if (!(p.embed.getPlaybackQuality() in ["highres", "hd1080", "hd720"])) {
            p.embed.setPlaybackQuality("hd720");
        }
        // toggleMastheadScale();
    });
    // p.on('pause', toggleMastheadScale);
    // p.on('ended', toggleMastheadScale);
}