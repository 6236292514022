import "core-js/stable";
import "regenerator-runtime/runtime";
import 'bootstrap'
require("@rails/ujs").start();

const Turbolinks = require("turbolinks");
Turbolinks.start();

import '../lib/iubenda-turbolinks'
import {scrollCatcher} from '../lib/scrollcatcher'
import {loadBeacon} from "../lib/beacon.js.erb";
import {initPlyr} from "../lib/plyr";
document.addEventListener("turbolinks:load", function () {
    initPlyr();
    scrollCatcher();
    $(window).scroll(scrollCatcher);

    let cm = $(".ldspk-embed.click-monitor");
    if(cm.length) {
        let ai = $("#additional-info");
        let cmInst = cm[0];
        let host = "https://" + cmInst.dataset.ldspkSite + ".onldspk.cc";

        window.addEventListener("message", function(e){
            if(e.origin === host && window.innerWidth >= 768) {
                if(e.data.startsWith("[iFrameSizer]iFrameResizer0") && e.data.endsWith("mutationObserver")) {
                    ai.fadeIn();
                } else if(e.data.startsWith("[iFrameSizer]iFrameResizer0") && e.data.endsWith("init")) {
                    ai.fadeOut();
                    document.getElementById("headerTop").scrollIntoView();
                }
            }
        }, false);
    }
});